//src/app/features/private/members/success-modal.component.ts
//Created success-model file for success message for members
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-modal',
  template: `
    <div class="modal-container">
      <div class="modal-content">
        <div class="modal-body">{{ data.body }}</div>
        <div class="modal-actions">
          <div class="button-wrapper">
            <button class="btn-ok" (click)="close()">OK</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .modal-container {
      padding: 20px;
      text-align: center;
    }
    .modal-body {
      margin-bottom: 30px;
      font-size: 16px;
    }
    .modal-actions {
      display: flex;
      justify-content: center;
    }
    .button-wrapper {
      display: inline-block;
    }
    .btn-ok {
      background-color: #4a90e2;
      color: white;
      border: none;
      border-radius: 30px;
      padding: 12px 40px;
      font-size: 16px;
      cursor: pointer;
      min-width: 195px;
      height: 48px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      font-weight: normal;
      text-transform: none;
      transition: background-color 0.2s;
    }
    .btn-ok:hover {
      background-color: #3a80d2;
    }
  `]
})
export class SuccessModalComponent {
  constructor(
    private dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public close(): void {
    this.dialogRef.close(true);
  }
}