import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public isLoading: boolean = true;
  public lastRefreshed: Date = new Date();

  constructor(private dashboardService: DashboardService) {}
  public membershipData: any[] = [];
  public airportData: any = [];
  public usersData: any = [];
  public view: any = [450, 300];
  // options
  public showXAxis: boolean = true;
  public showYAxis: boolean = true;
  public gradient: boolean = false;
  public showLegend: boolean = false;
  public showXAxisLabel: boolean = true;
  public xAxisLabel: string = 'Membership';
  public showYAxisLabel: boolean = true;
  public yAxisLabel: string = 'Users';
  public legendPosition: any = 'below';

  public membershipColorScheme: any = {
    domain: ['#63c2de', '#f8cb00', '#7879f1']
  };

  public userColorScheme: any = {
    domain: ['#5AA454', '#7aa3e5']
  };

  public upcomingFlightBookings: any = {
    allUpcomingTripCount: 0,
    nextWeekTripCount: 0,
    immediateTripCount: 0
  };

  public upcomingHotelBookings: any = {
    allUpcomingTripCount: 0,
    nextWeekTripCount: 0,
    immediateTripCount: 0
  };

  ngOnInit(): void {
    this.loadDashboardData();

    // Subscribe to immediate hotel check-ins count updates
    this.subscription.add(
      this.dashboardService.immediateHotelCheckinsCount$.subscribe((count) => {
        if (count >= 0 && this.upcomingHotelBookings) {
          this.upcomingHotelBookings.immediateTripCount = count;
        }
      })
    );

    // Subscribe to immediate flight count updates
    this.subscription.add(
      this.dashboardService.immediateFlightsCount$.subscribe((count) => {
        if (count >= 0 && this.upcomingFlightBookings) {
          this.upcomingFlightBookings.immediateTripCount = count;
        }
      })
    );

    // Subscribe to next week hotel check-ins count updates
    this.subscription.add(
      this.dashboardService.nextWeekHotelCheckinsCount$.subscribe((count) => {
        if (count >= 0 && this.upcomingHotelBookings) {
          this.upcomingHotelBookings.nextWeekTripCount = count;
        }
      })
    );

    // Subscribe to next week flight count updates
    this.subscription.add(
      this.dashboardService.nextWeekFlightsCount$.subscribe((count) => {
        if (count >= 0 && this.upcomingFlightBookings) {
          this.upcomingFlightBookings.nextWeekTripCount = count;
        }
      })
    );
  }

  ngOnDestroy(): void {
    // Clean up subscriptions
    this.subscription.unsubscribe();
  }

  /**
   * Refresh dashboard data
   */
  public refreshDashboard(): void {
    this.loadDashboardData();
  }

  /**
   * Load dashboard data from the API
   */
  private loadDashboardData(): void {
    // Set loading state
    this.isLoading = true;

    // Get statistics from backend
    this.subscription.add(
      this.dashboardService
        .getStatistics()
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.lastRefreshed = new Date();
          })
        )
        .subscribe(
          (res: any) => {
            this.updateDashboardData(res);
          },
          (error) => {
            console.error('Error loading dashboard data:', error);
          }
        )
    );
  }

  /**
   * Update dashboard data with API response
   */
  private updateDashboardData(res: any): void {
    if (res && res.data) {
      this.upcomingFlightBookings = res.data.flights;
      this.upcomingHotelBookings = res.data.hotels;

      this.usersData = [
        {
          name: `Total users: ${res.data.totalMember}`,
          value: res.data.totalMember
        },
        {
          name: `User registered last 24 hours: ${res.data.registerMemberInLast24}`,
          value: res.data.registerMemberInLast24
        }
      ];

      this.membershipData = res.data.memberByMembership.map((data: any) => {
        return {
          name: `${data.membershipLevel}: ${data.count}`,
          value: data.count
        };
      });

      this.airportData = res.data.memberByAirport.map((data: any) => {
        return {
          name: data.airportCode,
          value: data.count
        };
      });
    }
  }
}
