import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../issue-ticket/issue-ticket.component';
import { ReusableConfirmationAlertComponent } from 'src/shared/components/reusable-confirmation-alert/reusable-confirmation-alert.component';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { MockDataService } from 'src/app/core/services/mock-data.service';


@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit, AfterViewInit {
  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private route: ActivatedRoute,
    // NEW: Inject the MockDataService
    private mockDataService: MockDataService
  ) {
    // NEW: Initialize testing mode from environment
    this.isTestingMode = environment.useMockData && !environment.production;
    this.mockDataService.setTestingMode(this.isTestingMode);
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public displayedColumns: string[] = [
    'bookingId',
    'bookingNumber',
    'memberId',
    'emailId',
    'passengerCount',
    'travelType',
    'fareType',
    'bookingDate',
    'fromDate',
    'toDate',
    'oneairTotalAmount',
    'bookingStatus',
    'paymentStatus',
    'supplier',
    'placeQueue',
    'cancelBooking',
    'checkVoidStatus',
    'issueTicket'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public fetchingImmediateData: boolean = false;
  public fetchingNextWeekData: boolean = false;
  public bookingList: any[] = [];
  public bookingListBackup: any[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = null;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public showImmediateFlightsHeader: boolean = false;
  public showNextWeekFlightsHeader: boolean = false;
  public isImmediateFilter: boolean = false;
  public isNextWeekFilter: boolean = false;
  public selectedFilterValue: string = 'All';
  public environment = environment;

  // NEW: Add flag for testing mode
  public isTestingMode: boolean = false;

  // Track active filters - NEW: Add this to track all active filters for AND logic
  public activeFilters = {
    status: 'All',
    fromDate: null as string | null,
    toDate: null as string | null,
    searchQuery: ''
  };

  public filterOptions: any = [
    { title: 'All', value: 'All' },
    { title: 'Cancelled', value: 'CANCELLED' },
    { title: 'Ticket Issued', value: 'TICKET_ISSUED' },
    { title: 'Pending Cancel', value: 'PENDING_CANCEL' },
    { title: 'Created', value: 'CREATED' },
    { title: 'Void', value: 'VOID' }
  ];

  public fromDate: FormControl = new FormControl(null);
  public toDate: FormControl = new FormControl(null);

  ngOnInit(): void {
    // Check if we should filter for immediate flight trips or next week trips
    this.route.queryParams.subscribe((params) => {
      this.isImmediateFilter = params['immediate'] === 'true';
      this.isNextWeekFilter = params['nextWeek'] === 'true';

      // If we've already loaded data and switched filters, apply them
      if (!this.loading && this.bookingList.length > 0) {
        if (this.isImmediateFilter) {
          this.filterImmediateFlights();
        } else if (this.isNextWeekFilter) {
          this.filterNextWeekFlights();
        }
      }
    });

    // Load all bookings data
    this.getAllBookings();
  }

  ngAfterViewInit(): void {
    // This ensures the paginator is properly connected after the view is initialized
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.configureSorting();
    }
  }

  /**
   * Refreshes the flight bookings list with the latest data
   * Resets all filters and search queries
   */
  public refreshList(): void {
    // Reset all filters
    this.activeFilters = {
      status: 'All',
      fromDate: null,
      toDate: null,
      searchQuery: ''
    };

    // Reset form controls
    this.searchControl.setValue('');
    this.fromDate.setValue(null);
    this.toDate.setValue(null);

    this.selectedFilterValue = 'All';

    // Reset filter headers and filter states (same as hotel-bookings component)
    this.showImmediateFlightsHeader = false;
    this.showNextWeekFlightsHeader = false;
    this.isImmediateFilter = false;
    this.isNextWeekFilter = false;

    // Show loading state
    this.loading = true;

    // Fetch fresh data
    this.getAllBookings();
  }

  private configureSorting(): void {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        const value = data[sortHeaderId];

        // Handle date columns
        if (['fromDate', 'toDate', 'bookingDate'].includes(sortHeaderId)) {
          return value ? new Date(value).getTime() : 0;
        }

        // Handle string columns for case-insensitive sorting
        if (['bookingId', 'bookingNumber', 'emailId', 'supplier', 'travelType', 'fareType'].includes(sortHeaderId)) {
          return value ? value.toString().toLowerCase() : '';
        }

        // Handle numeric amounts - Fix for oneairTotalAmount sorting
        if (sortHeaderId === 'oneairTotalAmount') {
          if (typeof value === 'number') {
            return value;
          } else if (typeof value === 'string') {
            // Remove currency symbols and other non-numeric characters before parsing
            return parseFloat(value.replace(/[^0-9.-]+/g, '') || '0');
          } else {
            return 0;
          }
        }

        // Default handling
        return value ? (typeof value === 'string' ? value.toLowerCase() : value) : '';
      };
    }
  }

  private formatBookingData(bookings: any[]): any[] {
    return bookings.map((booking) => ({
      ...booking,
      // Make sure all required fields have a default value
      emailId: booking.member?.emailId || '',
      passengerCount: booking.passengerCount || 0,
      travelType: booking.travelType || '',
      fareType: booking.fareType || '',
      fromDate: booking.fromDate || '',
      toDate: booking.toDate || '',
      oneairTotalAmount:
        typeof booking.oneairTotalAmount === 'string'
          ? parseFloat(booking.oneairTotalAmount.replace(/[^0-9.-]+/g, '') || '0')
          : parseFloat(booking.oneairTotalAmount || 0), // Ensure oneairTotalAmount is a number
      bookingStatus: booking.bookingStatus || '',
      paymentStatus: booking.paymentStatus || '',
      supplier: booking.supplier || ''
    }));
  }

  public getAllBookings(): void {
    this.loading = true;
    
    // NEW: Log the current testing mode
    console.log(`Loading bookings in ${this.isTestingMode ? 'MOCK' : 'REAL'} data mode`);
    
    this.dashboardService.getAllBookings().subscribe(
      (res: any) => {
        if (res && res.data && Array.isArray(res.data.tickets)) {
          // Format the booking data to ensure all required fields are present
          this.bookingList = this.formatBookingData(res.data.tickets);
          this.bookingListBackup = [...this.bookingList];

          // Create new datasource with formatted data
          this.dataSource = new MatTableDataSource<any>(this.bookingList);

          // Ensure paginator and sort are attached
          setTimeout(() => {
            if (this.paginator && this.sort) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.configureSorting();
            }
          });

          // Ensure all oneairTotalAmount values are numeric for proper sorting
          this.bookingList = this.bookingList.map((booking) => ({
            ...booking,
            oneairTotalAmount:
              typeof booking.oneairTotalAmount === 'string'
                ? parseFloat(booking.oneairTotalAmount.replace(/[^0-9.-]+/g, '') || '0')
                : parseFloat(booking.oneairTotalAmount || 0)
          }));

          // Calculate and update all flight counts for consistency
          this.calculateAndUpdateAllFlightCounts();

          // Apply filters if requested
          if (this.isImmediateFilter) {
            this.filterImmediateFlights();
          } else if (this.isNextWeekFilter) {
            this.filterNextWeekFlights();
          }
        } else {
          console.warn('Invalid or empty booking data:', res);
          this.bookingList = [];
          this.bookingListBackup = [];
          this.dataSource = new MatTableDataSource<any>([]);
        }
        this.loading = false;
      },
      (err: any) => {
        console.error('Error loading flight bookings:', err);
        this.bookingList = [];
        this.bookingListBackup = [];
        this.dataSource = new MatTableDataSource<any>([]);
        this.loading = false;
      }
    );
  }

  // Calculate and update all flight counts
  private calculateAndUpdateAllFlightCounts(): void {
    // Calculate immediate flights count - only TICKET_ISSUED or BOOKED
    const now = moment();
    const twoDaysLater = moment().add(2, 'days');

    const immediateFlights = this.bookingList.filter((booking: any) => {
      if (!booking.fromDate) return false;

      const fromDate = moment(booking.fromDate);
      const isWithinTimeframe =
        fromDate.isSame(now, 'day') ||
        (fromDate.isAfter(now) && fromDate.isBefore(twoDaysLater)) ||
        fromDate.isSame(twoDaysLater, 'day');

      const hasValidStatus = booking.bookingStatus === 'TICKET_ISSUED' || booking.bookingStatus === 'BOOKED';

      return isWithinTimeframe && hasValidStatus;
    });

    const immediateCount = immediateFlights.length;
    if (this.dashboardService.updateImmediateFlightsCount) {
      this.dashboardService.updateImmediateFlightsCount(immediateCount);
    }

    // Calculate next week flights count for consistency - only TICKET_ISSUED or BOOKED
    const today = moment().startOf('day');
    const nextWeek = moment().add(7, 'days').endOf('day');

    const nextWeekFlights = this.bookingList.filter((booking: any) => {
      if (!booking.fromDate) return false;

      const fromDate = moment(booking.fromDate);
      const isWithinTimeframe =
        (fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) ||
        fromDate.isSame(today, 'day') ||
        fromDate.isSame(nextWeek, 'day');

      const hasValidStatus = booking.bookingStatus === 'TICKET_ISSUED' || booking.bookingStatus === 'BOOKED';

      return isWithinTimeframe && hasValidStatus;
    });

    const nextWeekCount = nextWeekFlights.length;
    if (this.dashboardService.updateNextWeekFlightsCount) {
      this.dashboardService.updateNextWeekFlightsCount(nextWeekCount);
    }
  }

  public timeConverter(minutes: number): any {
    const seconds = minutes * 60;
    const h = Math.floor(minutes / 60);
    const m = Math.floor((seconds % 3600) / 60);
    const hDisplay = h > 0 ? h + 'h ' : '';
    const mDisplay = m > 0 ? m + 'm' : '';
    return (hDisplay + mDisplay).replace(/,\s*$/, '');
  }

  public totalTripTime(data: any): string {
    if (!data || !Array.isArray(data)) return '';

    const totalDuration = data
      .map((_d: any) => {
        if (!_d) return 0;
        if (typeof _d.layoverDuration !== 'undefined') {
          return _d.duration + _d.layoverDuration;
        } else return _d.duration || 0;
      })
      .reduce((a: any, b: any) => a + b, 0);
    return this.timeConverter(totalDuration);
  }

  private updateDataSource(data: any[]): void {
    this.dataSource = new MatTableDataSource<any>(data);

    // Ensure paginator and sort are attached
    setTimeout(() => {
      if (this.paginator && this.sort) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.configureSorting();
      }
    });
  }

  private filterImmediateFlights(): void {
    // Wait for data to be loaded
    if (this.loading) {
      setTimeout(() => this.filterImmediateFlights(), 500);
      return;
    }

    this.fetchingImmediateData = true;

    // Define a 48-hour window for immediate flights
    const now = moment();
    const twoDaysLater = moment().add(2, 'days');

    // Filter flights scheduled within the next 48 hours AND with status TICKET_ISSUED or BOOKED
    const immediateFlights = this.bookingList.filter((booking: any) => {
      if (!booking.fromDate) return false;

      const fromDate = moment(booking.fromDate);
      const isWithinTimeframe =
        fromDate.isSame(now, 'day') ||
        (fromDate.isAfter(now) && fromDate.isBefore(twoDaysLater)) ||
        fromDate.isSame(twoDaysLater, 'day');

      const hasValidStatus = booking.bookingStatus === 'TICKET_ISSUED' || booking.bookingStatus === 'BOOKED';

      return isWithinTimeframe && hasValidStatus;
    });

    // Sort by date (earliest first)
    immediateFlights.sort((a: any, b: any) => {
      return moment(a.fromDate).diff(moment(b.fromDate));
    });

    // Update the data source with the filtered flights
    this.updateDataSource(immediateFlights);

    // Set UI indicators
    this.showImmediateFlightsHeader = true;
    this.showNextWeekFlightsHeader = false;

    // Update the count in the dashboard service for consistency
    if (this.dashboardService.updateImmediateFlightsCount) {
      this.dashboardService.updateImmediateFlightsCount(immediateFlights.length);
    }

    this.fetchingImmediateData = false;

    // Apply any existing filters to the immediate flights
    this.applyAllFilters();
  }

  private filterNextWeekFlights(): void {
    // Wait for data to be loaded
    if (this.loading) {
      setTimeout(() => this.filterNextWeekFlights(), 500);
      return;
    }

    this.fetchingNextWeekData = true;

    // Define the next week window (today to 7 days from now)
    const today = moment().startOf('day');
    const nextWeek = moment().add(7, 'days').endOf('day');

    // Filter flights with fromDate in the next week AND with status TICKET_ISSUED or BOOKED
    const nextWeekFlights = this.bookingList.filter((booking: any) => {
      if (!booking.fromDate) return false;

      const fromDate = moment(booking.fromDate);
      const isWithinTimeframe =
        (fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) ||
        fromDate.isSame(today, 'day') ||
        fromDate.isSame(nextWeek, 'day');

      const hasValidStatus = booking.bookingStatus === 'TICKET_ISSUED' || booking.bookingStatus === 'BOOKED';

      return isWithinTimeframe && hasValidStatus;
    });

    // Sort them by date (earliest first)
    nextWeekFlights.sort((a: any, b: any) => {
      return moment(a.fromDate).diff(moment(b.fromDate));
    });

    // Update the data source with the filtered flights
    this.updateDataSource(nextWeekFlights);

    // Set UI indicators
    this.showNextWeekFlightsHeader = true;
    this.showImmediateFlightsHeader = false;

    // Update the next week flight count in the dashboard service
    if (this.dashboardService.updateNextWeekFlightsCount) {
      this.dashboardService.updateNextWeekFlightsCount(nextWeekFlights.length);
    }

    this.fetchingNextWeekData = false;

    // Apply any existing filters to the next week flights
    this.applyAllFilters();
  }

  public calendarChange(event: any, type: string): void {
    // FIX: Only filter when the event has a specific date selected
    // The "Done" button without selection will pass an event but without startDate
    if (event && event.startDate) {
      const searchDate = moment(event.startDate).format('YYYY-MM-DD');

      // Update the appropriate filter
      if (type === 'from') {
        this.activeFilters.fromDate = searchDate;
      } else if (type === 'to') {
        this.activeFilters.toDate = searchDate;
      }

      // Apply all filters together
      this.applyAllFilters();
    } else if (event === null || (event && !event.startDate)) {
      // Reset the appropriate filter
      if (type === 'from') {
        this.activeFilters.fromDate = null;
      } else if (type === 'to') {
        this.activeFilters.toDate = null;
      }

      // Apply remaining filters
      this.applyAllFilters();
    }
  }

  public statusChange(value: string): void {
    // Update status filter
    this.activeFilters.status = value;
    this.selectedFilterValue = value;

    // Apply all filters together
    this.applyAllFilters();
  }

  private applyAllFilters(): void {
    // Start with the appropriate base data set
    let filteredData = [...this.bookingListBackup];

    // If immediate or next week filters are active, start with those filtered sets
    if (this.isImmediateFilter) {
      const now = moment();
      const twoDaysLater = moment().add(2, 'days');

      filteredData = filteredData.filter((data: any) => {
        if (!data.fromDate) return false;

        const fromDate = moment(data.fromDate);
        const isWithinTimeframe =
          fromDate.isSame(now, 'day') ||
          (fromDate.isAfter(now) && fromDate.isBefore(twoDaysLater)) ||
          fromDate.isSame(twoDaysLater, 'day');

        const hasValidStatus = data.bookingStatus === 'TICKET_ISSUED' || data.bookingStatus === 'BOOKED';

        return isWithinTimeframe && hasValidStatus;
      });

      this.showImmediateFlightsHeader = true;
      this.showNextWeekFlightsHeader = false;
    } else if (this.isNextWeekFilter) {
      const today = moment().startOf('day');
      const nextWeek = moment().add(7, 'days').endOf('day');

      filteredData = filteredData.filter((data: any) => {
        if (!data.fromDate) return false;

        const fromDate = moment(data.fromDate);
        const isWithinTimeframe =
          (fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) ||
          fromDate.isSame(today, 'day') ||
          fromDate.isSame(nextWeek, 'day');

        const hasValidStatus = data.bookingStatus === 'TICKET_ISSUED' || data.bookingStatus === 'BOOKED';

        return isWithinTimeframe && hasValidStatus;
      });

      this.showNextWeekFlightsHeader = true;
      this.showImmediateFlightsHeader = false;
    } else {
      this.showImmediateFlightsHeader = false;
      this.showNextWeekFlightsHeader = false;
    }

    // Apply status filter (if not 'All')
    if (this.activeFilters.status !== 'All') {
      filteredData = filteredData.filter((data: any) => data.bookingStatus === this.activeFilters.status);
    }

    if (this.activeFilters.fromDate && this.activeFilters.toDate) {
      // Both from and to dates are selected, filter by range
      const fromDate = moment(this.activeFilters.fromDate).startOf('day');
      const toDate = moment(this.activeFilters.toDate).endOf('day');

      filteredData = filteredData.filter((data: any) => {
        if (!data.fromDate) return false;

        const departureDate = moment(data.fromDate).startOf('day');
        return departureDate.isSameOrAfter(fromDate) && departureDate.isSameOrBefore(toDate);
      });
    } else if (this.activeFilters.fromDate) {
      // Only from date is selected, filter by exact match
      filteredData = filteredData.filter((data: any) => data.fromDate === this.activeFilters.fromDate);
    } else if (this.activeFilters.toDate) {
      // Only to date is selected, filter by exact match for toDate
      filteredData = filteredData.filter((data: any) => data.toDate === this.activeFilters.toDate);
    }

    // Apply search query if present
    if (this.activeFilters.searchQuery) {
      const query = this.activeFilters.searchQuery.toLowerCase();
      filteredData = filteredData.filter(
        (data: any) =>
          (data.bookingNumber && data.bookingNumber.toLowerCase().includes(query)) ||
          (data.bookingId && data.bookingId.toLowerCase().includes(query)) ||
          (data.memberId && data.memberId.toString().includes(query)) ||
          (data.member && data.member.emailId && data.member.emailId.toLowerCase().includes(query)) ||
          (data.bookingDetails &&
            Array.isArray(data.bookingDetails) &&
            data.bookingDetails.some(
              (detail: any) =>
                (detail.passengerFirstname && detail.passengerFirstname.toLowerCase().includes(query)) ||
                (detail.passengerLastname && detail.passengerLastname.toLowerCase().includes(query))
            ))
      );
    }

    // Update the data source with the filtered data
    this.updateDataSource(filteredData);
  }

  public searchItem(): void {
    this.searchControl.setValue(this.searchControl.value?.trim() || '');
    this.activeFilters.searchQuery = this.searchControl.value || '';

    // Apply all filters together
    this.applyAllFilters();
  }

  public resetSearch(event: string): void {
    if (!event) {
      this.activeFilters.searchQuery = '';
      this.applyAllFilters();
    }
  }

  public announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public placeQueue(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      data: { body: `Are you sure you want to place booking number: ${data.bookingNumber} in ticketing queue?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          bookingId: data.bookingId,
          bookingNumber: data.bookingNumber
        };
        this.dashboardService.placeQueue(form).subscribe((res: any) => {
          this.getAllBookings();
        });
      }
    });
  }

  public cancelBooking(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to cancel booking number: ${data.bookingNumber}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          bookingId: data.bookingId
        };
        this.dashboardService.paymentVoid(form).subscribe(
          (res: any) => {
            this.getAllBookings();
          },
          (err: any) => {
            if (err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message }
              });
            }
          }
        );
      }
    });
  }

  public showDetails(data: any): void {
    this.dashboardService.viewBooking(data.memberId, data.bookingId).subscribe((res: any) => {
      this.showBookingDetails = true;
      const departure = res.data.flights.filter((d: any) => !d.segmentFlag);
      const arrival = res.data.flights.filter((d: any) => d.segmentFlag);
      this.bookingDetails = {
        ...res.data,
        arrival,
        departure
      };
    });
  }

  public backToList(): void {
    this.showBookingDetails = false;

    // Re-apply appropriate filter when going back to list
    this.applyAllFilters();
  }

  public checkVoidStatus(data: any): void {
    const form = {
      memberId: data.memberId,
      bookingId: data.bookingId
    };
    this.dashboardService.checkTicketVoidStatus(form).subscribe(
      (res: any) => {
        this.dialog.open(ReusableConfirmationAlertComponent, {
          autoFocus: false,
          data: {
            message: res.message,
            yesMessage: 'OK',
            hasNoButton: false
          },
          panelClass: 'remove-destination-dialog'
        });
      },
      (err: any) => {
        this.dialog.open(ReusableConfirmationAlertComponent, {
          autoFocus: false,
          data: {
            message: err.error.message,
            yesMessage: 'OK',
            hasNoButton: false
          },
          panelClass: 'remove-destination-dialog'
        });
      }
    );
  }
  
  public issueTicket(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to issue ticket for booking number: ${data.bookingNumber}?` }
    });
    
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          bookingNumber: data.bookingNumber
        };
        
        this.dashboardService.getFaresIssueTicketForHoldBooking(form).subscribe(
          (res: any) => {
            this.dialog.open(ReusableConfirmationAlertComponent, {
              autoFocus: false,
              data: {
                message: 'Ticket issued successfully',
                yesMessage: 'OK',
                hasNoButton: false
              },
              panelClass: 'remove-destination-dialog'
            });
            this.getAllBookings(); // Refresh the list after successful ticket issuance
          },
          (err: any) => {
            this.dialog.open(ReusableConfirmationAlertComponent, {
              autoFocus: false,
              data: {
                message: err.error?.message || 'Failed to issue ticket. Please try again.',
                yesMessage: 'OK',
                hasNoButton: false
              },
              panelClass: 'remove-destination-dialog'
            });
          }
        );
      }
    });
  }

  
  public toggleTestingMode(): void {
    // Only allowed in non-production environments
    if (environment.production) {
      console.warn('Testing mode cannot be toggled in production environment');
      return;
    }
    
    this.isTestingMode = !this.isTestingMode;
    this.mockDataService.setTestingMode(this.isTestingMode);
    
    console.log(`Testing mode: ${this.isTestingMode ? 'ON' : 'OFF'}`);
    
    // Reload data with new mode
    this.refreshList();
  }
  
  
  public showIssueTicketButton(booking: any): boolean {
    return booking.supplier === 'GetFares' && booking.bookingStatus === 'BOOKED';
  }
  
  
  public showAddToTicketQueueButton(booking: any): boolean {
    return booking.bookingStatus === 'BOOKED' && booking.supplier !== 'GetFares';
  }
}