import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Import your services
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';
import { FlightBookingService } from './services/flight-booking.service';
import { MockDataService } from './services/mock-data.service';
import { SimulatorService } from './services/simulator.service';

// Import interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule  // Adding HttpClientModule if not already in AppModule
  ],
  providers: [
    // Register all services
    AuthService,
    DashboardService,
    FlightBookingService,
    MockDataService,  // Mock data service for loading JSON files
    SimulatorService, // Higher-level service for app-wide testing
    
    // Keep existing interceptor configuration
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }