import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { MockDataService } from './mock-data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private http: HttpClient,
    private mockDataService: MockDataService  // Inject MockDataService
  ) {
    console.log('DashboardService initialized, mock data enabled:', environment.useMockData);
  }

  public baseUrl: string = environment.apiBaseUrl;
  public keyHex: any = CryptoJS.enc.Utf8.parse(`-----BEGIN RSA PUBLIC KEY-----
    MIIBCgKCAQEAz5ES0ThVPFuS9VWC/YOrxOmrRBADlFvphXkPltM9silmhgp7Sy1X
    RVmi3MaCmOmtOAwpNV4C1SIbvD3AXYVsxSUmrGGmnS7sFxwbc4gwOKFOJJamzqnv
    8m5T1MPBDUI5Frku3XBhecBHH/zCxoClS9BGplNI6uq9TrMDHqi9aiT6eXiA75eZ
    3qJJaqZRapQprNrkvEygFjhNNMG1AuiYxpBRefOtallpPNcBDWqz+v6blsOS9de6
    LBqa28ZH1yDSQux5fREDlee4ev7U90CK1s4g2ZB27zPRYeLw/D7wtBVbo37W3Hb1
    wRzLpdG/h098VhBXFg36TibIN0o0SvOEOQIDAQAB
    -----END RSA PUBLIC KEY-----`);

  // BehaviorSubjects for trip counts
  private _nextWeekFlightsCount = new BehaviorSubject<number>(0);
  public nextWeekFlightsCount$ = this._nextWeekFlightsCount.asObservable();

  private _nextWeekHotelCheckinsCount = new BehaviorSubject<number>(0);
  public nextWeekHotelCheckinsCount$ = this._nextWeekHotelCheckinsCount.asObservable();

  private _immediateHotelCheckinsCount = new BehaviorSubject<number>(0);
  public immediateHotelCheckinsCount$ = this._immediateHotelCheckinsCount.asObservable();

  private _immediateFlightsCount = new BehaviorSubject<number>(0);
  public immediateFlightsCount$ = this._immediateFlightsCount.asObservable();

  public updateImmediateFlightsCount(count: number): void {
    this._immediateFlightsCount.next(count);
  }

  public updateNextWeekFlightsCount(count: number): void {
    this._nextWeekFlightsCount.next(count);
  }

  public updateImmediateCheckinsCount(count: number): void {
    this._immediateHotelCheckinsCount.next(count);
  }

  public updateNextWeekHotelCheckinsCount(count: number): void {
    this._nextWeekHotelCheckinsCount.next(count);
  }

  // Store the filtered immediate flights for consistency
  private _cachedImmediateFlights: any[] = [];

  // Cache for hotel bookings to minimize API calls
  private _hotelBookingsCache: any[] = [];
  private _lastHotelBookingsFetch: number = 0;
  private _cacheDuration: number = 60000; // 1 minute cache

  // Cache for flight bookings to minimize API calls
  private _flightBookingsCache: any[] = [];
  private _lastFlightBookingsFetch: number = 0;

  // Issue ticket for GetFares hold booking - modified to use mock data
  public getFaresIssueTicketForHoldBooking(data: { bookingNumber: string }): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for GetFares issue ticket:', data.bookingNumber);
      return this.mockDataService.getMockData<any>('issue-ticket').pipe(
        map((result: any) => {
          // Clear caches when changes occur
          this._flightBookingsCache = [];
          this._cachedImmediateFlights = [];
          return result;
        })
      );
    }

    // Use the real API if not in testing mode
    const endpoint = `${this.baseUrl}/getFaresIssueTicketForHoldBooking`;
    
    return this.http.post(endpoint, data).pipe(
      map((result: any) => {
        // Clear caches when changes occur
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public checkTicketVoidStatus(form: any): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for ticket void status');
      return this.mockDataService.getMockData<any>('ticket-void-status');
    }

    const endpoint = `${this.baseUrl}/booking/checkTicketVoidStatus`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public memberVerified(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/verified`;

    return this.http.put(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public deleteUser(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/delete`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public paymentVoid(form: any): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for payment void');
      return this.mockDataService.getMockData<any>('payment-void');
    }

    const endpoint = `${this.baseUrl}/booking/paymentVoid`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public deactivateRedemptionCode(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/deactivateRedemptionCode`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public sendDealEmailToMember(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/sendDealEmailToMember`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public viewBooking(memberId: number, bookingId: string): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for view booking');
      return this.mockDataService.getMockData<any>('booking-details');
    }

    const endpoint = `${this.baseUrl}/tickets/${memberId}/${bookingId}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => {
        return {
          ...result,
          data: JSON.parse(
            CryptoJS.DES.decrypt(result.data, this.keyHex, {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8)
          )
        };
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public placeQueue(form: any): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for place queue');
      return this.mockDataService.getMockData<any>('place-queue').pipe(
        map((result: any) => {
          // Clear caches when changes occur
          this._flightBookingsCache = [];
          this._cachedImmediateFlights = [];
          return result;
        })
      );
    }

    const endpoint = `${this.baseUrl}/placeQueue`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => {
        // Clear caches when changes occur
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public bookingCancel(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/booking/cancel`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => {
        // Clear caches when changes occur
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public memberBlock(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/member/status`;

    return this.http.put(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public recentlySignupMembers(): Observable<any> {
    const endpoint = `${this.baseUrl}/member/recentlySignupMembers`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public memberSearch(search: string): Observable<any> {
    const endpoint = `${this.baseUrl}/member/search?text=${search}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }
  
  // deleteMemberByAdmin() for members list page
  public deleteMemberByAdmin(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/deleteMemberByAdmin`;
  
    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  // Main method to get statistics without overriding with calculations
  public getStatistics(): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for statistics');
      return this.mockDataService.getMockData<any>('statistics').pipe(
        tap((result: any) => {
          if (result?.data) {
            // Update the BehaviorSubjects with data from mock
            if (result.data.hotels) {
              this._immediateHotelCheckinsCount.next(result.data.hotels.immediateTripCount);
              this._nextWeekHotelCheckinsCount.next(result.data.hotels.nextWeekTripCount);
            }

            if (result.data.flights) {
              this._immediateFlightsCount.next(result.data.flights.immediateTripCount);
              this._nextWeekFlightsCount.next(result.data.flights.nextWeekTripCount);
            }
          }
        })
      );
    }

    const endpoint = `${this.baseUrl}/statistic`;

    return this.http.get(endpoint).pipe(
      tap((result: any) => {
        if (result?.data) {
          // Update the BehaviorSubjects with data directly from API
          if (result.data.hotels) {
            this._immediateHotelCheckinsCount.next(result.data.hotels.immediateTripCount);
            this._nextWeekHotelCheckinsCount.next(result.data.hotels.nextWeekTripCount);
          }

          if (result.data.flights) {
            this._immediateFlightsCount.next(result.data.flights.immediateTripCount);
            this._nextWeekFlightsCount.next(result.data.flights.nextWeekTripCount);
          }
        }
      }),
      catchError((err: any) => throwError(err))
    );
  }

  // Get immediate flights (for consistency between components)
  public getImmediateFlights(): Observable<any[]> {
    // If we already have filtered flights, return them
    if (this._cachedImmediateFlights.length > 0) {
      return of(this._cachedImmediateFlights);
    }

    // Otherwise, fetch and filter
    return this.getAllBookings().pipe(
      map((res: any) => {
        if (res?.data?.tickets) {
          const now = moment();
          const twoDaysLater = moment().add(2, 'days');

          // Apply the same filtering logic used by the bookings component
          const immediateFlights = res.data.tickets.filter((booking: any) => {
            if (!booking.fromDate) return false;
            const fromDate = moment(booking.fromDate);
            return (
              fromDate.isSame(now, 'day') ||
              (fromDate.isAfter(now) && fromDate.isBefore(twoDaysLater)) ||
              fromDate.isSame(twoDaysLater, 'day')
            );
          });

          // Sort by date (earliest first)
          immediateFlights.sort((a: any, b: any) => {
            return moment(a.fromDate).diff(moment(b.fromDate));
          });

          // Cache the filtered flights
          this._cachedImmediateFlights = immediateFlights;

          return immediateFlights;
        }
        return [];
      })
    );
  }

  // Get next week flights (for consistency between components)
  public getNextWeekFlights(): Observable<any[]> {
    return this.getAllBookings().pipe(
      map((res: any) => {
        if (res?.data?.tickets) {
          const today = moment().startOf('day');
          const nextWeek = moment().add(7, 'days').endOf('day');

          // Applying the same filtering logic used by the bookings component
          const nextWeekFlights = res.data.tickets.filter((booking: any) => {
            if (!booking.fromDate) return false;

            const fromDate = moment(booking.fromDate);
            return (
              (fromDate.isAfter(today) && fromDate.isBefore(nextWeek)) ||
              fromDate.isSame(today, 'day') ||
              fromDate.isSame(nextWeek, 'day')
            );
          });

          // Sort by date (earliest first)
          nextWeekFlights.sort((a: any, b: any) => {
            return moment(a.fromDate).diff(moment(b.fromDate));
          });

          return nextWeekFlights;
        }
        return [];
      })
    );
  }

  public routes(fromCityId: number, region: string, cabinCode: string): Observable<any> {
    const endpoint = `${
      this.baseUrl
    }/routes?fromCityId=${fromCityId}&region=${region.toString()}&cabinCode=${cabinCode}`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateRoute(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/updateRoute`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public submitDeals(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/route/months`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAirlines(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAirlines`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getActiveArrivalCities(): Observable<any> {
    const endpoint = `${this.baseUrl}/getActiveArrivalCities`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getFromAirports(): Observable<any> {
    return this.http.get('../../../assets/activeAirports.json').pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public issueTicket(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/issueTicket`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => {
        // Clear caches when changes occur
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllCities(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAllCities`;

    return this.http.post(endpoint, {}).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public cityUpdate(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/cityUpdate`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllOneAirRoutes(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAllOneAirRoutes`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllMissingRoutes(): Observable<any> {
    const endpoint = `${this.baseUrl}/missing/route`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public oneAirRouteUpdate(form: any): Observable<any> {
    const endpoint = `${this.baseUrl}/oneAirRouteUpdate`;

    return this.http.post(endpoint, form).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllBookings(): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Loading flight bookings from mock data');
      return this.mockDataService.getMockData<any>('flight-bookings').pipe(
        map((result: any) => {
          console.log('Mock flight bookings data loaded successfully');
          // Clear immediate flights cache to ensure fresh filtering
          this._cachedImmediateFlights = [];
          return result;
        })
      );
    }

    console.log('Loading flight bookings from real API');
    const endpoint = `${this.baseUrl}/tickets`;

    // Use cached data if available and not expired
    const now = Date.now();
    if (this._flightBookingsCache.length > 0 && now - this._lastFlightBookingsFetch < this._cacheDuration) {
      return of({ data: { tickets: this._flightBookingsCache } });
    }

    return this.http.get(endpoint).pipe(
      map((result: any) => {
        // Cache the bookings data for future use
        if (result?.data?.tickets) {
          this._flightBookingsCache = result.data.tickets;
          this._lastFlightBookingsFetch = now;

          // Clear immediate flights cache to ensure fresh filtering
          this._cachedImmediateFlights = [];
        }
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelBookings(): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Loading hotel bookings from mock data');
      return this.mockDataService.getMockData<any>('hotel-bookings');
    }

    console.log('Loading hotel bookings from real API');
    const endpoint = `${this.baseUrl}/getAllBookings`;

    // Use cached data if available and not expired
    const now = Date.now();
    if (this._hotelBookingsCache.length > 0 && now - this._lastHotelBookingsFetch < this._cacheDuration) {
      return of({ data: { bookings: this._hotelBookingsCache } });
    }

    return this.http.get(endpoint).pipe(
      map((result: any) => {
        // Cache the bookings data for future use
        if (result?.data?.bookings) {
          this._hotelBookingsCache = result.data.bookings;
          this._lastHotelBookingsFetch = now;
        }
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public triggerAirTicketScheduler(): Observable<any> {
    const endpoint = `${this.baseUrl}/startOrderIssueScheduler`;
    const data = {};

    return this.http.post(endpoint, data).pipe(
      map((result: any) => {
        // Clear caches when changes occur
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public getHotelBookingDetail(data: any): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for hotel booking detail');
      return this.mockDataService.getMockData<any>('hotel-booking-detail');
    }

    const endpoint = `${this.baseUrl}/getBookingDetail`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public cancelHotelBookings(data: any): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log('Using mock data for cancel hotel booking');
      return this.mockDataService.getMockData<any>('cancel-hotel-booking');
    }

    const endpoint = `${this.baseUrl}/cancelBooking`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => {
        // Clear cache when a booking is canceled
        this._hotelBookingsCache = [];
        return throwError(err);
      })
    );
  }

  public getAllFlightSearches(): Observable<any> {
    const endpoint = `${this.baseUrl}/flight-searches`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelSearches(): Observable<any> {
    const endpoint = `${this.baseUrl}/hotelSearches`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public bookingAction(data: any, url: string): Observable<any> {
    // Check if we should use mock data
    if (this.mockDataService && this.mockDataService.isInTestingMode()) {
      console.log(`Using mock data for booking action: ${url}`);
      return this.mockDataService.getMockData<any>('booking-action');
    }

    const endpoint = `${this.baseUrl}/${url}`;
    return this.http.post(endpoint, data).pipe(
      map((result: any) => {
        // Clear cache when a booking action is performed
        this._hotelBookingsCache = [];
        // Also clear flight bookings cache as the action might affect flights
        this._flightBookingsCache = [];
        this._cachedImmediateFlights = [];
        return result;
      }),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAffiliates(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAffiliates`;
    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateAffiliateStatus(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/updateAffiliate`;
    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllHotelTrackingList(): Observable<any> {
    const endpoint = `${this.baseUrl}/track/hotelBookings`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public getAllAirlineCommission(): Observable<any> {
    const endpoint = `${this.baseUrl}/getAirlineCommission`;

    return this.http.get(endpoint).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }

  public updateAirlineCommission(data: any): Observable<any> {
    const endpoint = `${this.baseUrl}/upsertAirlineCommission`;

    return this.http.post(endpoint, data).pipe(
      map((result: any) => result),
      catchError((err: any) => throwError(err))
    );
  }
}