import { Component, OnInit } from '@angular/core';
import { MockDataService } from '../../../app/core/services/mock-data.service';

@Component({
  selector: 'app-simulator-control',
  templateUrl: './simulator-control.component.html',
  styleUrls: ['./simulator-control.component.scss']
})
export class SimulatorControlComponent implements OnInit {
  isSimulatorMode = false;
  showSimulator = false;

  constructor(private mockDataService: MockDataService) {}

  ngOnInit(): void {
    this.showSimulator = this.mockDataService.shouldShowSimulator();
    this.isSimulatorMode = this.mockDataService.isTestingMode();
  }

  toggleSimulatorMode(): void {
    this.mockDataService.setTestingMode(!this.mockDataService.isTestingMode());
    this.isSimulatorMode = this.mockDataService.isTestingMode();
    // Force page refresh to apply changes across all components
    window.location.reload();
  }
}