// src/app/core/services/simulator.service.ts

import { Injectable } from '@angular/core';
import { MockDataService } from './mock-data.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {
  
  constructor(private mockDataService: MockDataService) {}

  /**
   * Generic method to get either mock data or call a real API
   * @param mockFileName Name of the mock data file
   * @param apiCall Function that makes the actual API call
   * @returns Observable with either mock or real data
   */
  getMockOrRealData<T>(mockFileName: string, apiCall: () => Observable<T>): Observable<T> {
    if (this.mockDataService.isInTestingMode() && environment.useMockData) {
      console.log(`Using mock data from ${mockFileName}.json`);
      return this.mockDataService.getMockData<T>(mockFileName);
    }
    
    return apiCall();
  }

  /**
   * Generic method to simulate a POST request
   * @param successResponse The successful response to return when in testing mode
   * @param apiCall Function that makes the actual API call
   * @returns Observable with either mock or real response
   */
  simulatePost<T>(successResponse: T, apiCall: () => Observable<T>): Observable<T> {
    if (this.mockDataService.isInTestingMode() && environment.useMockData) {
      console.log(`Simulating POST request with response:`, successResponse);
      return of(successResponse);
    }
    
    return apiCall();
  }

  /**
   * Capture a real API response and save it for future mock use
   * @param response The API response to capture
   * @param fileName Name to save the mock file as
   */
  captureApiResponse(response: any, fileName: string): void {
    console.log(`To use this as mock data, create a file at src/assets/mocks/${fileName}.json with this content:`);
    console.log(JSON.stringify(response, null, 2));
  }
}