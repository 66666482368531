// src/app/core/services/mock-data.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {
  // Storage key for localStorage
  private readonly STORAGE_KEY = 'simulator_testing_mode';
  
  // Renamed property with an underscore to avoid conflicts
  private _isTestingMode = true;
  
  constructor(private http: HttpClient) {
    // Check if we should read from localStorage
    if (environment.useMockData && !environment.production) {
      const storedValue = localStorage.getItem(this.STORAGE_KEY);
      // Use the stored value if available, otherwise default to true
      this._isTestingMode = storedValue !== null ? storedValue === 'true' : true;
    } else {
      this._isTestingMode = false;
    }
    
    console.log('MockDataService initialized with testingMode:', this._isTestingMode);
  }

  /**
   * Gets mock data from local assets folder
   * @param mockFileName Name of the mock JSON file (e.g. 'tickets')
   * @returns Observable with the mock data
   */
  getMockData<T>(mockFileName: string): Observable<T> {
    const filePath = `assets/mocks/${mockFileName}.json`;
    console.log(`Loading mock data from: ${filePath}`);
    
    return this.http.get<T>(filePath).pipe(
      tap(data => console.log(`Successfully loaded mock data from ${mockFileName}.json`)),
      catchError(error => {
        console.error(`Error loading mock data from ${filePath}:`, error);
        console.error('Make sure the file exists in src/assets/mocks/ and is valid JSON');
        return throwError(() => error);
      })
    );
  }

  /**
   * Toggle testing mode - if true, use mock data instead of API calls
   */
  setTestingMode(isTestingMode: boolean): void {
    this._isTestingMode = isTestingMode;
    // Store the setting in localStorage for persistence
    if (this.shouldShowSimulator()) {
      localStorage.setItem(this.STORAGE_KEY, isTestingMode.toString());
    }
    console.log(`Testing mode ${isTestingMode ? 'enabled' : 'disabled'}`);
  }

  /**
   * Check if testing mode is enabled
   */
  isTestingMode(): boolean {
    return this._isTestingMode && environment.useMockData && !environment.production;
  }
  
  /**
   * Alias for isTestingMode for backward compatibility
   */
  isInTestingMode(): boolean {
    return this.isTestingMode();
  }
  
  /**
   * Check if simulator should be shown
   * Only show simulator in non-production environment when mock data is enabled
   */
  shouldShowSimulator(): boolean {
    return environment.useMockData && !environment.production;
  }
}