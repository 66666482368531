// reusable-calendar.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reusable-calendar',
  templateUrl: './reusable-calendar.component.html',
  styleUrls: ['./reusable-calendar.component.scss']
})
export class ReusableCalendarComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() customClass: string = '';
  @Input() control: any;
  @Input() isError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() readOnly: boolean = false;
  @Input() min: any = null;
  @Input() max: any = null;
  @Output() calendarChange = new EventEmitter();
  @Input() isRange: boolean = false;
  @Input() label: string = '';

  public selected: any = {
    startDate: null,
    endDate: null
  };

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.selected.startDate = this.min;
    this.selected.endDate = this.max;
  }

  public onChange(event: any): void {
    this.calendarChange.emit(event);
  }
}