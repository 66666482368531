// src/app/core/services/flight-booking.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MockDataService } from './mock-data.service';

@Injectable({
  providedIn: 'root'
})
export class FlightBookingService {
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private mockDataService: MockDataService
  ) {}

  /**
   * Get all flight bookings
   * Uses mock data when in testing mode
   */
  getAllBookings(): Observable<any> {
    // Check if we're in testing mode
    if (this.mockDataService.isInTestingMode() && environment.useMockData) {
      console.log('Using mock flight bookings data');
      return this.mockDataService.getMockData<any>('flight-bookings');
    }
    
    // Otherwise use the real API
    return this.http.get(`${this.apiBaseUrl}/tickets`).pipe(
      catchError(error => {
        console.error('Error fetching flight bookings:', error);
        return of({ tickets: [] });
      })
    );
  }

  /**
   * Issue ticket for a hold booking (GetFares)
   * Uses mock data when in testing mode
   */
  getFaresIssueTicketForHoldBooking(bookingNumber: string): Observable<any> {
    if (this.mockDataService.isInTestingMode() && environment.useMockData) {
      console.log(`Mock: Issue ticket for booking ${bookingNumber}`);
      // Simulate successful response
      return of({ success: true, message: 'Ticket issued successfully' });
    }
    
    return this.http.post(`${this.apiBaseUrl}/getFaresIssueTicketForHoldBooking`, {
      bookingNumber: bookingNumber
    }).pipe(
      catchError(error => {
        console.error('Error issuing ticket:', error);
        return of({ success: false, message: 'Failed to issue ticket' });
      })
    );
  }

  /**
   * Add booking to ticket queue
   * Uses mock data when in testing mode
   */
  addToTicketQueue(bookingNumber: string): Observable<any> {
    if (this.mockDataService.isInTestingMode() && environment.useMockData) {
      console.log(`Mock: Add booking ${bookingNumber} to ticket queue`);
      // Simulate successful response
      return of({ success: true, message: 'Added to ticket queue successfully' });
    }
    
    return this.http.post(`${this.apiBaseUrl}/addToTicketQueue`, {
      bookingNumber: bookingNumber
    }).pipe(
      catchError(error => {
        console.error('Error adding to ticket queue:', error);
        return of({ success: false, message: 'Failed to add to ticket queue' });
      })
    );
  }
}