import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../issue-ticket/issue-ticket.component';
import { ReusableConfirmationAlertComponent } from 'src/shared/components/reusable-confirmation-alert/reusable-confirmation-alert.component';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hotel-bookings',
  templateUrl: './hotel-bookings.component.html',
  styleUrls: ['./hotel-bookings.component.scss']
})
export class HotelBookingsComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private route: ActivatedRoute
  ) {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public displayedColumns: string[] = [
    'bookingId',
    'supplierConfNumber',
    'memberId',
    'emailId',
    'cityName',
    'hotelName',
    'guestCount',
    'roomCount',
    'checkinDate',
    'checkoutDate',
    'bookingDate',
    'oneairTotalAmount',
    'bookingStatus',
    'paymentStatus',
    'supplier',
    'voidStatus',
    'refundStatus',
    'settleStatus'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public detailsLoading: boolean = false;
  public bookingList: any[] = [];
  public bookingListBackup: any[] = [];
  public showBookingDetails: boolean = false;
  public hotelBookingDetails: any = null;
  public hotelDetailInformation: any = null;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public indiaCountry: boolean = false;
  public showImmediateCheckInsHeader: boolean = false;
  public showNextWeekCheckInsHeader: boolean = false;
  public isImmediateFilter: boolean = false;
  public isNextWeekFilter: boolean = false;
  public selectedFilterValue: string = 'All';
  // Track active filters
  public activeFilters = {
    status: 'All',
    checkInDate: null as string | null,
    checkOutDate: null as string | null,
    searchQuery: ''
  };

  public filterOptions: any = [
    { title: 'All', value: 'All' },
    { title: 'Confirmed', value: 'CONFIRMED' },
    { title: 'Cancelled', value: 'Cancelled' },
    { title: 'CREATED', value: 'CREATED' },
    { title: 'Failed', value: 'FAILED' }
  ];

  public fromDate: FormControl = new FormControl(null);
  public toDate: FormControl = new FormControl(null);

  ngOnInit(): void {
    this.fromDate.setValue(null);
    this.toDate.setValue(null);

    // Check if we should filter for immediate or next week hotel check-ins
    this.route.queryParams.subscribe((params) => {
      this.isImmediateFilter = params['immediate'] === 'true';
      this.isNextWeekFilter = params['nextWeek'] === 'true';

      // If we've already loaded data and switched filters, apply them
      if (!this.loading && this.bookingList.length > 0) {
        if (this.isImmediateFilter) {
          this.filterImmediateCheckIns();
        } else if (this.isNextWeekFilter) {
          this.filterNextWeekCheckIns();
        }
      }
    });

    // Load all hotel bookings data
    this.getAllHotelBookings();
  }

  ngAfterViewInit(): void {
    // Make sure paginator gets assigned after dataSource is populated
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.configureSorting();
    }
  }

  public refreshList(): void {
    // Reset all filters
    this.activeFilters = {
      status: 'All',
      checkInDate: null,
      checkOutDate: null,
      searchQuery: ''
    };

    // Reset form controls
    this.searchControl.setValue('');
    this.fromDate.setValue(null);
    this.toDate.setValue(null);

    // Update the selected filter value for the dropdown
    this.selectedFilterValue = 'All';

    // Reset filter headers and filter states
    this.showImmediateCheckInsHeader = false;
    this.showNextWeekCheckInsHeader = false;
    this.isImmediateFilter = false;
    this.isNextWeekFilter = false;

    // Reset date input placeholders
    this.resetDateFields();

    // Show loading state
    this.loading = true;

    // Fetch fresh data
    this.getAllHotelBookings();
  }

  private resetDateFields(): void {
    // Get all the date input elements and reset their placeholder values
    setTimeout(() => {
      const dateInputs = document.querySelectorAll('app-reusable-calendar input');
      if (dateInputs && dateInputs.length > 0) {
        dateInputs.forEach((input) => {
          (input as HTMLInputElement).placeholder = 'Enter date';
        });
      }
    }, 0);
  }

  public filterImmediateCheckIns(): void {
    // Wait for data to be loaded
    if (this.loading) {
      setTimeout(() => this.filterImmediateCheckIns(), 500);
      return;
    }

    // Get the current date and date 2 days from now for the 48-hour window
    const now = moment();
    const twoDaysLater = moment().add(2, 'days');

    const result = this.bookingList.filter((data: any) => {
      if (!data.checkInDate) return false;

      const checkInDate = moment(data.checkInDate);
      const isWithinTimeframe =
        checkInDate.isSame(now, 'day') ||
        (checkInDate.isAfter(now) && checkInDate.isBefore(twoDaysLater)) ||
        checkInDate.isSame(twoDaysLater, 'day');

      // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
      const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

      return isWithinTimeframe && isConfirmed;
    });

    // Sort by check-in date
    result.sort((a: any, b: any) => {
      return moment(a.checkInDate).diff(moment(b.checkInDate));
    });

    // Update the accurate count in the dashboard service for consistency
    if (this.dashboardService.updateImmediateCheckinsCount) {
      this.dashboardService.updateImmediateCheckinsCount(result.length);
    }

    this.updateDataSource(result);
    this.showImmediateCheckInsHeader = true;
    this.showNextWeekCheckInsHeader = false;

    // Apply any existing filters to the immediate check-ins
    this.applyAllFilters();
  }

  public filterNextWeekCheckIns(): void {
    // Wait for data to be loaded
    if (this.loading) {
      setTimeout(() => this.filterNextWeekCheckIns(), 500);
      return;
    }

    // Define the next week window (today to 7 days from now)
    const today = moment().startOf('day');
    const nextWeek = moment().add(7, 'days').endOf('day');

    const result = this.bookingList.filter((data: any) => {
      if (!data.checkInDate) return false;

      const checkInDate = moment(data.checkInDate);
      const isWithinTimeframe =
        (checkInDate.isAfter(today) && checkInDate.isBefore(nextWeek)) ||
        checkInDate.isSame(today, 'day') ||
        checkInDate.isSame(nextWeek, 'day');

      // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
      const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

      return isWithinTimeframe && isConfirmed;
    });

    // Sort by check-in date
    result.sort((a: any, b: any) => {
      return moment(a.checkInDate).diff(moment(b.checkInDate));
    });

    // Update the next week hotel check-ins count in the dashboard service
    if (this.dashboardService.updateNextWeekHotelCheckinsCount) {
      this.dashboardService.updateNextWeekHotelCheckinsCount(result.length);
    }

    this.updateDataSource(result);
    this.showNextWeekCheckInsHeader = true;
    this.showImmediateCheckInsHeader = false;

    // Apply any existing filters to the next week check-ins
    this.applyAllFilters();
  }

  private configureSorting(): void {
    if (this.dataSource) {
      this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        const value = data[sortHeaderId];

        if (['checkinDate', 'checkoutDate', 'bookingDate'].includes(sortHeaderId)) {
          const dateValue = data[this.getDateFieldName(sortHeaderId)];
          return dateValue ? new Date(dateValue).getTime() : 0;
        }

        if (['cityName', 'supplier', 'hotelName', 'emailId'].includes(sortHeaderId)) {
          return value ? value.toString().toLowerCase() : '';
        }

        if (sortHeaderId === 'oneairTotalAmount') {
          return typeof value === 'string'
            ? parseFloat(value.replace(/[^0-9.-]+/g, '') || '0')
            : parseFloat(value || 0);
        }

        return value ? (typeof value === 'string' ? value.toLowerCase() : value) : '';
      };
    }
  }

  private getDateFieldName(sortHeaderId: string): string {
    const dateFieldMap: { [key: string]: string } = {
      checkinDate: 'checkInDate',
      checkoutDate: 'checkOutDate',
      bookingDate: 'createDate'
    };
    return dateFieldMap[sortHeaderId] || sortHeaderId;
  }

  private formatBookingData(bookings: any[]): any[] {
    return bookings.map((booking) => ({
      ...booking,
      emailId: booking.member?.emailId || '',
      cityName: booking.city || '',
      supplier: booking.supplierName || '',
      checkInDate: booking.checkInDate || null,
      checkOutDate: booking.checkOutDate || null,
      createDate: booking.createDate || null,
      oneairTotalAmount: booking.oneairTotalAmount || 0,
      hotelName: booking.hotelName || ''
    }));
  }

  public getAllHotelBookings(): void {
    this.loading = true;
    this.dashboardService.getAllHotelBookings().subscribe(
      (res: any) => {
        if (res && res.data && Array.isArray(res.data.bookings)) {
          // Format the booking data to ensure all required fields are present
          this.bookingList = this.formatBookingData(res.data.bookings);
          this.bookingListBackup = [...this.bookingList];

          // Create new datasource with formatted data
          this.dataSource = new MatTableDataSource<any>(this.bookingList);

          // Manually attach paginator and sort as we're outside ngAfterViewInit
          setTimeout(() => {
            if (this.paginator && this.sort) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.configureSorting();
            }
          });

          this.calculateAndUpdateImmediateCheckinsCount();

          // Apply filters based on query parameters
          if (this.isImmediateFilter) {
            this.filterImmediateCheckIns();
          } else if (this.isNextWeekFilter) {
            this.filterNextWeekCheckIns();
          }
        } else {
          console.warn('Invalid or empty booking data:', res);
          this.bookingList = [];
          this.bookingListBackup = [];
          this.dataSource = new MatTableDataSource<any>([]);
        }
        this.loading = false;
      },
      (err: any) => {
        console.error('Error loading hotel bookings:', err);
        this.bookingList = [];
        this.bookingListBackup = [];
        this.dataSource = new MatTableDataSource<any>([]);
        this.loading = false;

        // Show error message to user
        this.dialog.open(AlertComponent, {
          width: '25vw',
          height: '200px',
          autoFocus: false,
          data: { message: 'Failed to load hotel bookings. Please try again later.' }
        });
      }
    );
  }

  private calculateAndUpdateImmediateCheckinsCount(): void {
    // Calculate immediate check-ins - only CONFIRMED status (case insensitive)
    const now = moment();
    const twoDaysLater = moment().add(2, 'days');

    const immediateCheckins = this.bookingList.filter((data: any) => {
      if (!data.checkInDate) return false;

      const checkInDate = moment(data.checkInDate);
      const isWithinTimeframe =
        checkInDate.isSame(now, 'day') ||
        (checkInDate.isAfter(now) && checkInDate.isBefore(twoDaysLater)) ||
        checkInDate.isSame(twoDaysLater, 'day');

      // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
      const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

      return isWithinTimeframe && isConfirmed;
    });

    const immediateCount = immediateCheckins.length;
    if (this.dashboardService.updateImmediateCheckinsCount) {
      this.dashboardService.updateImmediateCheckinsCount(immediateCount);
    }

    // Also calculate next week check-ins for consistency - only CONFIRMED status (case insensitive)
    const today = moment().startOf('day');
    const nextWeek = moment().add(7, 'days').endOf('day');

    const nextWeekCheckins = this.bookingList.filter((data: any) => {
      if (!data.checkInDate) return false;

      const checkInDate = moment(data.checkInDate);
      const isWithinTimeframe =
        (checkInDate.isAfter(today) && checkInDate.isBefore(nextWeek)) ||
        checkInDate.isSame(today, 'day') ||
        checkInDate.isSame(nextWeek, 'day');

      // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
      const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

      return isWithinTimeframe && isConfirmed;
    });

    const nextWeekCount = nextWeekCheckins.length;
    if (this.dashboardService.updateNextWeekHotelCheckinsCount) {
      this.dashboardService.updateNextWeekHotelCheckinsCount(nextWeekCount);
    }
  }

  public announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  public calendarChange(event: any, type: string): void {
    if (event && event.startDate) {
      const dateFormat = 'YYYY-MM-DD';
      const searchDate = moment(event.startDate).format(dateFormat);

      // Update the appropriate filter
      if (type === 'from') {
        this.activeFilters.checkInDate = searchDate;
      } else {
        this.activeFilters.checkOutDate = searchDate;
      }

      // Apply all filters together
      this.applyAllFilters();
    } else if (event === null || (event && !event.startDate)) {
      // Reset the appropriate filter
      if (type === 'from') {
        this.activeFilters.checkInDate = null;
      } else {
        this.activeFilters.checkOutDate = null;
      }

      // Apply remaining filters
      this.applyAllFilters();
    }
  }

  public statusChange(value: string): void {
    // Update status filter
    this.activeFilters.status = value;
    this.selectedFilterValue = value;

    // Apply all filters together
    this.applyAllFilters();
  }

  /**
   * Applies all active filters using AND logic
   * Modified to implement date range filtering
   */
  private applyAllFilters(): void {
    // Start with the appropriate base data set
    let filteredData = [...this.bookingListBackup];

    // If immediate or next week filters are active, start with those filtered sets
    if (this.isImmediateFilter) {
      const now = moment();
      const twoDaysLater = moment().add(2, 'days');

      filteredData = filteredData.filter((data: any) => {
        if (!data.checkInDate) return false;

        const checkInDate = moment(data.checkInDate);
        const isWithinTimeframe =
          checkInDate.isSame(now, 'day') ||
          (checkInDate.isAfter(now) && checkInDate.isBefore(twoDaysLater)) ||
          checkInDate.isSame(twoDaysLater, 'day');

        // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
        const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

        return isWithinTimeframe && isConfirmed;
      });

      this.showImmediateCheckInsHeader = true;
      this.showNextWeekCheckInsHeader = false;
    } else if (this.isNextWeekFilter) {
      const today = moment().startOf('day');
      const nextWeek = moment().add(7, 'days').endOf('day');

      filteredData = filteredData.filter((data: any) => {
        if (!data.checkInDate) return false;

        const checkInDate = moment(data.checkInDate);
        const isWithinTimeframe =
          (checkInDate.isAfter(today) && checkInDate.isBefore(nextWeek)) ||
          checkInDate.isSame(today, 'day') ||
          checkInDate.isSame(nextWeek, 'day');

        // Check if bookingStatus is "CONFIRMED" or "confirmed" (case insensitive)
        const isConfirmed = data.bookingStatus && data.bookingStatus.toLowerCase() === 'confirmed';

        return isWithinTimeframe && isConfirmed;
      });

      this.showNextWeekCheckInsHeader = true;
      this.showImmediateCheckInsHeader = false;
    } else {
      this.showImmediateCheckInsHeader = false;
      this.showNextWeekCheckInsHeader = false;
    }

    // Apply status filter (if not 'All')
    if (this.activeFilters.status !== 'All') {
      filteredData = filteredData.filter(
        (data: any) =>
          data.bookingStatus && data.bookingStatus.toLowerCase() === this.activeFilters.status.toLowerCase()
      );
    }

    // Apply date range filter for check-in and check-out dates
    if (this.activeFilters.checkInDate && this.activeFilters.checkOutDate) {
      // Both from and to dates are selected, filter by range
      const fromDate = moment(this.activeFilters.checkInDate).startOf('day');
      const toDate = moment(this.activeFilters.checkOutDate).endOf('day');

      filteredData = filteredData.filter((data: any) => {
        if (!data.checkInDate) return false;

        const checkInDate = moment(data.checkInDate).startOf('day');
        return checkInDate.isSameOrAfter(fromDate) && checkInDate.isSameOrBefore(toDate);
      });
    } else if (this.activeFilters.checkInDate) {
      // Only from date is selected, filter by exact match
      filteredData = filteredData.filter((data: any) => data.checkInDate === this.activeFilters.checkInDate);
    } else if (this.activeFilters.checkOutDate) {
      // Only to date is selected, filter by exact match for checkout date
      filteredData = filteredData.filter((data: any) => data.checkOutDate === this.activeFilters.checkOutDate);
    }

    // Apply search query if present
    if (this.activeFilters.searchQuery) {
      const query = this.activeFilters.searchQuery.toLowerCase();
      filteredData = filteredData.filter(
        (data: any) =>
          (data.hotelBookingId && data.hotelBookingId.toLowerCase().includes(query)) ||
          (data.supplierConfNumber && data.supplierConfNumber.toLowerCase().includes(query)) ||
          (data.memberId && data.memberId.toString().includes(query)) ||
          (data.emailId && data.emailId.toLowerCase().includes(query))
      );
    }

    // Update the data source with the filtered data
    this.updateDataSource(filteredData);
  }

  private updateDataSource(data: any[]): void {
    this.dataSource = new MatTableDataSource<any>(data);

    // Ensure paginator attachment
    setTimeout(() => {
      if (this.paginator && this.sort) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.configureSorting();
      }
    });
  }

  public searchItem(): void {
    const query = this.searchControl.value?.trim() || '';
    this.activeFilters.searchQuery = query;

    // Apply all filters together
    this.applyAllFilters();
  }

  public resetSearch(event: string): void {
    if (!event) {
      this.activeFilters.searchQuery = '';
      this.applyAllFilters();
    }
  }

  public clearDateFilters(): void {
    this.fromDate.setValue(null);
    this.toDate.setValue(null);
    this.activeFilters.checkInDate = null;
    this.activeFilters.checkOutDate = null;

    // Apply remaining filters
    this.applyAllFilters();
  }

  public showDetails(data: any): void {
    // Validate input data
    if (!data || !data.memberId || !data.hotelBookingId) {
      console.error('Invalid booking data provided', data);
      // Show an error notification to the user
      this.dialog.open(AlertComponent, {
        width: '25vw',
        height: '200px',
        autoFocus: false,
        data: { message: 'Unable to load booking details. Invalid booking data.' }
      });
      return;
    }

    // Show loading indicator
    this.detailsLoading = true;
    this.showBookingDetails = true;

    this.dashboardService
      .getHotelBookingDetail({
        memberId: data.memberId,
        hotelBookingId: data.hotelBookingId
      })
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.hotelDetailInformation = res.data;
            this.hotelBookingDetails = data;
            this.indiaCountry = this.hotelBookingDetails?.member?.memberCountryCode === 'IN';
          } else {
            console.error('Invalid response data', res);
            this.dialog.open(AlertComponent, {
              width: '25vw',
              height: '200px',
              autoFocus: false,
              data: { message: 'Unable to load booking details. Please try again.' }
            });
          }
          this.detailsLoading = false;
        },
        (err: any) => {
          console.error('Error fetching hotel booking details:', err);
          this.dialog.open(AlertComponent, {
            width: '25vw',
            height: '200px',
            autoFocus: false,
            data: { message: err.error?.message || 'Unable to load booking details. Please try again.' }
          });
          this.detailsLoading = false;
          this.showBookingDetails = false;
        }
      );
  }

  public calculateRatings(rating: string | number): number {
    if (!rating) return 0;
    const numericRating = typeof rating === 'string' ? parseFloat(rating) : rating;
    return Math.round((numericRating / 5) * 100);
  }

  public getGuests(rooms: any): string {
    if (!rooms || !Array.isArray(rooms) || rooms.length === 0) return 'No guests';

    try {
      let guestList = [];
      for (const room of rooms) {
        if (room.guests && Array.isArray(room.guests)) {
          for (const guest of room.guests) {
            const name = [guest.firstName, guest.middleName, guest.lastName].filter((n) => n).join(' ');
            if (name) {
              guestList.push(name);
            }
          }
        }
      }

      return guestList.length > 0 ? guestList.join(', ') : 'No guests';
    } catch (error) {
      console.error('Error processing guest information:', error);
      return 'Error retrieving guest information';
    }
  }

  public setOccupants(data: any): string {
    if (!data) return 'No occupants';

    try {
      const adults = data.numOfAdults || data.adults || 0;
      const children = data.numOfChildren || data.children || 0;

      let result = [];
      if (adults > 0) {
        result.push(`${adults} ${adults === 1 ? 'Adult' : 'Adults'}`);
      }

      if (children > 0) {
        result.push(`${children} ${children === 1 ? 'Child' : 'Children'}`);
      }

      return result.length > 0 ? result.join(', ') : 'No occupants';
    } catch (error) {
      console.error('Error processing occupant information:', error);
      return 'Error retrieving occupant information';
    }
  }

  public backToList(): void {
    this.showBookingDetails = false;
    this.hotelDetailInformation = null;
    this.hotelBookingDetails = null;

    // Re-apply appropriate filter when going back to list
    this.applyAllFilters();
  }

  public cancelBooking(data: any): void {
    // Validate input data
    if (!data || !data.memberId || !data.hotelBookingId) {
      console.error('Invalid booking data provided', data);
      this.dialog.open(AlertComponent, {
        width: '25vw',
        height: '200px',
        autoFocus: false,
        data: { message: 'Unable to cancel booking. Invalid booking data.' }
      });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to cancel booking number: ${data.hotelBookingId}?` }
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.loading = true;
        const form = {
          memberId: data.memberId,
          hotelBookingId: data.hotelBookingId
        };
        this.dashboardService.cancelHotelBookings(form).subscribe(
          (res: any) => {
            this.loading = false;
            this.dialog.open(AlertComponent, {
              width: '25vw',
              height: '200px',
              autoFocus: false,
              data: { message: 'Booking cancelled successfully.' }
            });
            this.getAllHotelBookings();
          },
          (err: any) => {
            this.loading = false;
            if (err.error && err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message || 'Failed to cancel booking.' }
              });
            } else {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: 'Failed to cancel booking. Please try again.' }
              });
            }
          }
        );
      }
    });
  }

  /**
   * Handle booking actions (void, refund, settle)
   */
  public bookingAction(data: any, type: string): void {
    // Validate input data
    if (!data || !data.memberId || !data.hotelBookingId) {
      console.error('Invalid booking data provided', data);
      this.dialog.open(AlertComponent, {
        width: '25vw',
        height: '200px',
        autoFocus: false,
        data: { message: `Unable to ${type} booking. Invalid booking data.` }
      });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to ${type} booking number: ${data.hotelBookingId}?` }
    });

    const url = type === 'void' ? 'voidHotelBooking' : type === 'refund' ? 'refundHotelBooking' : 'settleTransaction';

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.loading = true;
        const form = {
          memberId: data.memberId,
          hotelBookingId: data.hotelBookingId
        };
        this.dashboardService.bookingAction(form, url).subscribe(
          (res: any) => {
            this.loading = false;
            this.dialog.open(AlertComponent, {
              width: '25vw',
              height: '200px',
              autoFocus: false,
              data: { message: `Booking ${type} action completed successfully.` }
            });
            this.getAllHotelBookings();
          },
          (err: any) => {
            this.loading = false;
            if (err.error && err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message || `Failed to ${type} booking.` }
              });
            } else {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: `Failed to ${type} booking. Please try again.` }
              });
            }
          }
        );
      }
    });
  }
}
